<div class="containerDiv">
    <div class="modal-header text-left">
        <h6>{{message}}</h6>
    </div>
    <div class="icon-mt-close-white closeIcon" (click)="onClose()"></div>
    <div class="formRow">
        <form [formGroup]="changePasswordForm" class="formElement">
            <div>
                <app-password-textfield  (change)="validateoldpassword()" id="oldPassword" [defaultValue]="changePasswordForm['controls'].oldPassword.value"
                formControlName="oldPassword" [placeholder]="'Old Password'" [type]="'text'" [label]="''"
                [disabled]="false">
                </app-password-textfield>
                <div class="errorDiv" [hidden]="!oldpasswordErrorMsg">
                    <div class="errorMsg">{{oldpasswordErrorMsg}}</div>
                </div>
            </div>
            <div>
                <app-password-textfield (ngModelChange)="ValidateNewPassword()"  id="newPassword" [defaultValue]="changePasswordForm['controls'].newPassword.value"
                formControlName="newPassword" [placeholder]="'New Password'" [type]="'text'" [label]="''"
                [disabled]="(changePasswordForm['controls'].oldPassword.value) && oldPasswordError"></app-password-textfield>
                <div class="errorDiv" *ngIf="newpasswordErrormsg">
                    <div class="errorMsg">{{newpasswordErrormsg}}</div>
                </div>
            </div>
            <div >
                <app-password-textfield (ngModelChange)="ValidateConfirmPassword()" id="confirmPassword" [defaultValue]="changePasswordForm['controls'].confirmPassword.value"
                formControlName="confirmPassword" [placeholder]="'Confirm Password'" [type]="'text'" [label]="''"
                [disabled]="((changePasswordForm['controls'].newPassword.status == 'INVALID') && (changePasswordForm['controls'].newPassword.value))"></app-password-textfield>
                <div class="errorDiv" *ngIf="confirmpasswordmsg">
                    <div class="errorMsg">{{confirmpasswordmsg}}</div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <app-button id="changePassSubmit" [disabled]="!((this.changePasswordForm['controls'].newPassword.value == this.changePasswordForm['controls'].confirmPassword.value) && confirmflag && (this.changePasswordForm.valid))" (click)="onSubmit()"
            [buttonText]="'Submit'"></app-button>
    </div>
</div>