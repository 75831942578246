import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Subject, Subscription } from "rxjs";

import { debounceTime } from "rxjs/operators";
import { RestApiService } from "../../_services/rest-api/rest-api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { LocalStorageService } from "../../_services/local-storage/localstorage.service";
import { DashboardService } from "src/app/_services/Dashboard/dashboard.service";

@Component({
  selector: "app-search-bar",
  templateUrl: "./search-bar.component.html",
  styleUrls: ["./search-bar.component.scss"],
})
export class SearchBarComponent implements OnInit {
  @Output() public onKeypress = new Subject<any>();
  @Input() lessonListFlag: boolean = false;
  @ViewChild("searchbutton") element: ElementRef;
  searchTextChanged = new Subject<string>();
  subscription = new Subscription();
  searchList: any = [];
  searchValue: any;
  therapistId: any;
  showInputSearchFlag: boolean = false;
  showSearchListFlag: boolean = false;
  errorMsgFlag: boolean = false;
  selectedLessonLevelId: any;
  selectedLevel: any;
  flowType: any;

  constructor(
    private api: RestApiService,
    private router: Router,
    private localStorage: LocalStorageService,
    private dashboardService: DashboardService,
    private route: ActivatedRoute,
    private elem: ElementRef
  ) {
    this.therapistId = this.localStorage.get("therapistId");
  }

  ngOnInit(): void {
    this.searchTextChanged.pipe(debounceTime(1000)).subscribe((term) => {
      this.selectedLessonLevelId = this.localStorage.get(
        "selectedLessonLevelId"
      );
      this.selectedLevel = this.localStorage.get("selectedLevel");
      this.flowType = this.localStorage.get("flowType");
      if (this.lessonListFlag) {
        this.errorMsgFlag = false;
        this.lessonList();
      } else {
        if (this.flowType === "preLingual") {
          this.errorMsgFlag = false;
          this.getAllPatients(term);
        } else if (this.flowType === "postLingual") {
          this.errorMsgFlag = false;
          this.fetchAllPatients(term);
        }
      }
    });
  }

  lessonList(): void {
    let request = {
      courseName: this.searchValue,
      courselevel: this.selectedLessonLevelId,
    };
    this.api.getSearchLessonDetailList(request).subscribe((res) => {
      if (res.response.length) {
        this.searchList = res.response;
        this.showSearchListFlag = true;
      } else {
        this.errorMsgFlag = true;
      }
    });
  }

  //post Lingual
  fetchAllPatients(searchValue) {
    // let requestBody = {
    //   keyword: searchValue,
    //   therapistId: this.therapistId,
    //   pageNumber: "1",
    //   recordsPerPage: 10,
    // };
    let requestBody = {
      keyword: "",
      therapistId: this.therapistId,
      pageNumber: "1",
      recordsPerPage: 10,
      patientCategory : this.flowType
    
    };
    this.api.fetchPostLinguaPatientList(requestBody).subscribe((res) => {
      // res = [
      //   {
      //     id: 271,
      //     patientFirstName: "lakshmi",
      //     patientLastName: "s",
      //     patientFullName: "lakshmi s",
      //     patientGender: "Female",
      //     isVaccinated: null,
      //     patientPhone: "9787449245",
      //     patientEmail: "lakshmii@hcl.com",
      //     patientDOB: "22-Jan-2021",
      //     patientImplantDate: "10-Dec-2021",
      //     notes: "She is in goood condition",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "11 M",
      //     patientImplantAge: "30 D",
      //     patientImplantGap: "11 M",
      //     careId: 0,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "17-Nov-2021",
      //       updatedAt: "17-Nov-2021",
      //       id: 82,
      //       bteSerialNumber: "BTE0000000199998",
      //       implantSerialNumber: "IMP0000000199998",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "09-Jan-2022",
      //     updatedAt: "09-Jan-2022",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 270,
      //     patientFirstName: "arunn",
      //     patientLastName: "prasadd",
      //     patientFullName: "arunn prasadd",
      //     patientGender: "Female",
      //     isVaccinated: null,
      //     patientPhone: "8787667654",
      //     patientEmail: "arun@hcl.com",
      //     patientDOB: "07-Jan-2021",
      //     patientImplantDate: "17-Dec-2021",
      //     notes: "He is in good condition",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "1 Y",
      //     patientImplantAge: "23 D",
      //     patientImplantGap: "1 Y",
      //     careId: 0,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "08-Jan-2022",
      //       updatedAt: "08-Jan-2022",
      //       id: 85,
      //       bteSerialNumber: "BTE0000000999902",
      //       implantSerialNumber: "IMP0000000999902",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "08-Jan-2022",
      //     updatedAt: "09-Jan-2022",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 269,
      //     patientFirstName: "rohini",
      //     patientLastName: "nadar",
      //     patientFullName: "rohini nadar",
      //     patientGender: "Female",
      //     isVaccinated: null,
      //     patientPhone: "9858384434",
      //     patientEmail: "rohininadar@gmail.com",
      //     patientDOB: "28-Dec-2011",
      //     patientImplantDate: "01-Jan-2022",
      //     notes: "all is well",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "10 Y",
      //     patientImplantAge: "8 D",
      //     patientImplantGap: "10 Y",
      //     careId: 0,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "17-Nov-2021",
      //       updatedAt: "17-Nov-2021",
      //       id: 83,
      //       bteSerialNumber: "BTE0000000199999",
      //       implantSerialNumber: "IMP0000000199999",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "08-Jan-2022",
      //     updatedAt: "08-Jan-2022",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 267,
      //     patientFirstName: "testt",
      //     patientLastName: "account",
      //     patientFullName: "testt account",
      //     patientGender: "Female",
      //     isVaccinated: null,
      //     patientPhone: "9787449245",
      //     patientEmail: "test@gmail.com",
      //     patientDOB: "07-Jan-2021",
      //     patientImplantDate: "01-Jan-2022",
      //     notes: "he is in good condition",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "1 Y",
      //     patientImplantAge: "8 D",
      //     patientImplantGap: "1 Y",
      //     careId: 0,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "17-Nov-2021",
      //       updatedAt: "17-Nov-2021",
      //       id: 75,
      //       bteSerialNumber: "BTE0000000000998",
      //       implantSerialNumber: "IMP0000000000998",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "08-Jan-2022",
      //     updatedAt: "08-Jan-2022",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 268,
      //     patientFirstName: "vineeth",
      //     patientLastName: "raj",
      //     patientFullName: "vineeth raj",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "9787449245",
      //     patientEmail: "vin@hcl.com",
      //     patientDOB: "28-Jan-2021",
      //     patientImplantDate: "01-Jan-2022",
      //     notes: "notes",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "11 M",
      //     patientImplantAge: "8 D",
      //     patientImplantGap: "11 M",
      //     careId: 0,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "17-Nov-2021",
      //       updatedAt: "17-Nov-2021",
      //       id: 79,
      //       bteSerialNumber: "BTE0000000199995",
      //       implantSerialNumber: "IMP0000000199995",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "08-Jan-2022",
      //     updatedAt: "08-Jan-2022",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 266,
      //     patientFirstName: "dev",
      //     patientLastName: "account",
      //     patientFullName: "dev account",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "9787449245",
      //     patientEmail: "dev@gmail.com",
      //     patientDOB: "07-Jan-2021",
      //     patientImplantDate: "01-Jan-2022",
      //     notes: "notes",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "1 Y",
      //     patientImplantAge: "8 D",
      //     patientImplantGap: "1 Y",
      //     careId: 0,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "17-Nov-2021",
      //       updatedAt: "17-Nov-2021",
      //       id: 74,
      //       bteSerialNumber: "BTE0000000000999",
      //       implantSerialNumber: "IMP0000000000999",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "08-Jan-2022",
      //     updatedAt: "08-Jan-2022",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 265,
      //     patientFirstName: "ravi",
      //     patientLastName: "rJ",
      //     patientFullName: "ravi rJ",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "9787449245",
      //     patientEmail: "RAVI@HCL.COM",
      //     patientDOB: "01-Jan-2022",
      //     patientImplantDate: "07-Jan-2022",
      //     notes: "B",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "8 D",
      //     patientImplantAge: "2 D",
      //     patientImplantGap: "6 D",
      //     careId: 0,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "17-Nov-2021",
      //       updatedAt: "17-Nov-2021",
      //       id: 73,
      //       bteSerialNumber: "BTE0000000000909",
      //       implantSerialNumber: "IMP0000000000909",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "07-Jan-2022",
      //     updatedAt: "07-Jan-2022",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 264,
      //     patientFirstName: "samuel",
      //     patientLastName: "wood",
      //     patientFullName: "samuel wood",
      //     patientGender: "Female",
      //     isVaccinated: null,
      //     patientPhone: "9787449245",
      //     patientEmail: "samuel@hcl.com",
      //     patientDOB: "03-Feb-2021",
      //     patientImplantDate: "03-Nov-2021",
      //     notes: "He is in good condition",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "11 M",
      //     patientImplantAge: "2 M",
      //     patientImplantGap: "9 M",
      //     careId: 377,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "17-Nov-2021",
      //       updatedAt: "17-Nov-2021",
      //       id: 78,
      //       bteSerialNumber: "BTE0000000000995",
      //       implantSerialNumber: "IMP0000000000995",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "17-Nov-2021",
      //     updatedAt: "29-Dec-2021",
      //     levelcompletionpercentage: 50,
      //   },
      //   {
      //     id: 263,
      //     patientFirstName: "santhosh",
      //     patientLastName: "Raj",
      //     patientFullName: "santhosh Raj",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "9787449245",
      //     patientEmail: "santhosh.ra@hcl.com",
      //     patientDOB: "02-Jan-2021",
      //     patientImplantDate: "04-Nov-2021",
      //     notes: "He is in Good condition",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "1 Y",
      //     patientImplantAge: "2 M",
      //     patientImplantGap: "10 M",
      //     careId: 376,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "17-Nov-2021",
      //       updatedAt: "17-Nov-2021",
      //       id: 77,
      //       bteSerialNumber: "BTE0000000000996",
      //       implantSerialNumber: "IMP0000000000996",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "17-Nov-2021",
      //     updatedAt: "17-Nov-2021",
      //     levelcompletionpercentage: 29,
      //   },
      //   {
      //     id: 262,
      //     patientFirstName: "Sri",
      //     patientLastName: "Priya",
      //     patientFullName: "Sri Priya",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "9787449245",
      //     patientEmail: "sir@hcl.com",
      //     patientDOB: "01-Jan-2021",
      //     patientImplantDate: "08-Oct-2021",
      //     notes: "She is fine",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "1 Y",
      //     patientImplantAge: "3 M",
      //     patientImplantGap: "9 M",
      //     careId: 375,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "14-Oct-2021",
      //       updatedAt: "14-Oct-2021",
      //       id: 71,
      //       bteSerialNumber: "BTE0000000099777",
      //       implantSerialNumber: "IMP0000000099777",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "22-Oct-2021",
      //     updatedAt: "22-Oct-2021",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 260,
      //     patientFirstName: "prakashhh",
      //     patientLastName: "raj",
      //     patientFullName: "prakashhh raj",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "9787449245",
      //     patientEmail: "praksash@hcl.com",
      //     patientDOB: "02-Jan-2021",
      //     patientImplantDate: "01-Oct-2021",
      //     notes: "He is in good condition",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "1 Y",
      //     patientImplantAge: "3 M",
      //     patientImplantGap: "9 M",
      //     careId: 373,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "21-Oct-2021",
      //       updatedAt: "21-Oct-2021",
      //       id: 72,
      //       bteSerialNumber: "BTE0000000001009",
      //       implantSerialNumber: "IMP0000000001009",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "21-Oct-2021",
      //     updatedAt: "08-Jan-2022",
      //     levelcompletionpercentage: 95,
      //   },
      //   {
      //     id: 261,
      //     patientFirstName: "Sri",
      //     patientLastName: "Divya",
      //     patientFullName: "Sri Divya",
      //     patientGender: "Female",
      //     isVaccinated: null,
      //     patientPhone: "9787449245",
      //     patientEmail: "sri@hcl.com",
      //     patientDOB: "01-May-2021",
      //     patientImplantDate: "07-Oct-2021",
      //     notes: "She is Perfect",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "8 M",
      //     patientImplantAge: "3 M",
      //     patientImplantGap: "5 M",
      //     careId: 374,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "21-Sep-2021",
      //       updatedAt: "21-Sep-2021",
      //       id: 64,
      //       bteSerialNumber: "BTE0000000099134",
      //       implantSerialNumber: "IMP0000000099134",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "21-Oct-2021",
      //     updatedAt: "21-Oct-2021",
      //     levelcompletionpercentage: 70,
      //   },
      //   {
      //     id: 259,
      //     patientFirstName: "kumaran",
      //     patientLastName: "s",
      //     patientFullName: "kumaran s",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "9787449245",
      //     patientEmail: "kumaran@hcl.com",
      //     patientDOB: "02-Jan-2021",
      //     patientImplantDate: "01-Oct-2021",
      //     notes: "He is in good condition",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "1 Y",
      //     patientImplantAge: "3 M",
      //     patientImplantGap: "9 M",
      //     careId: 372,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "12-Oct-2021",
      //       updatedAt: "12-Oct-2021",
      //       id: 67,
      //       bteSerialNumber: "BTE0000000099992",
      //       implantSerialNumber: "IMP0000000099992",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "20-Oct-2021",
      //     updatedAt: "20-Oct-2021",
      //     levelcompletionpercentage: 72,
      //   },
      //   {
      //     id: 258,
      //     patientFirstName: "Ravindra",
      //     patientLastName: "jadeja",
      //     patientFullName: "Ravindra jadeja",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "8999999999",
      //     patientEmail: "jaddu12@hcl.com",
      //     patientDOB: "10-Oct-2012",
      //     patientImplantDate: "16-Oct-2015",
      //     notes: "required app",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "9 Y",
      //     patientImplantAge: "6 Y",
      //     patientImplantGap: "3 Y",
      //     careId: 371,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "12-Oct-2021",
      //       updatedAt: "12-Oct-2021",
      //       id: 70,
      //       bteSerialNumber: "BTE0000000099995",
      //       implantSerialNumber: "IMP0000000099995",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "12-Oct-2021",
      //     updatedAt: "12-Oct-2021",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 254,
      //     patientFirstName: "Senthan",
      //     patientLastName: "R",
      //     patientFullName: "Senthan R",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "8000000000",
      //     patientEmail: "senthan@hcl.com",
      //     patientDOB: "08-Oct-2015",
      //     patientImplantDate: "12-Oct-2017",
      //     notes: "therapist session needs to start",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "6 Y",
      //     patientImplantAge: "4 Y",
      //     patientImplantGap: "2 Y",
      //     careId: 370,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "12-Oct-2021",
      //       updatedAt: "12-Oct-2021",
      //       id: 66,
      //       bteSerialNumber: "BTE0000000099991",
      //       implantSerialNumber: "IMP0000000099991",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "12-Oct-2021",
      //     updatedAt: "12-Oct-2021",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 257,
      //     patientFirstName: "Kevin",
      //     patientLastName: "peterson",
      //     patientFullName: "Kevin peterson",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "8939000000",
      //     patientEmail: "petersonkevin@hcl.com",
      //     patientDOB: "07-Oct-2015",
      //     patientImplantDate: "17-Oct-2017",
      //     notes: "serial number added",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "6 Y",
      //     patientImplantAge: "4 Y",
      //     patientImplantGap: "2 Y",
      //     careId: 369,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "12-Oct-2021",
      //       updatedAt: "12-Oct-2021",
      //       id: 69,
      //       bteSerialNumber: "BTE0000000099994",
      //       implantSerialNumber: "IMP0000000099994",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "12-Oct-2021",
      //     updatedAt: "12-Oct-2021",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 256,
      //     patientFirstName: "kavin",
      //     patientLastName: "A",
      //     patientFullName: "kavin A",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "9876765675",
      //     patientEmail: "kavin.a@gmail.com",
      //     patientDOB: "12-Oct-2011",
      //     patientImplantDate: "30-Sep-2021",
      //     notes: "NA",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "10 Y",
      //     patientImplantAge: "3 M",
      //     patientImplantGap: "9 Y",
      //     careId: 368,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "12-Oct-2021",
      //       updatedAt: "12-Oct-2021",
      //       id: 68,
      //       bteSerialNumber: "BTE0000000099993",
      //       implantSerialNumber: "IMP0000000099993",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "12-Oct-2021",
      //     updatedAt: "12-Oct-2021",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 253,
      //     patientFirstName: "janani",
      //     patientLastName: "kumar",
      //     patientFullName: "janani kumar",
      //     patientGender: "Female",
      //     isVaccinated: null,
      //     patientPhone: "9787449245",
      //     patientEmail: "janani@hcl.com",
      //     patientDOB: "01-Jan-2021",
      //     patientImplantDate: "01-Oct-2021",
      //     notes: "She is good codition",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "1 Y",
      //     patientImplantAge: "3 M",
      //     patientImplantGap: "9 M",
      //     careId: 362,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "21-Sep-2021",
      //       updatedAt: "21-Sep-2021",
      //       id: 63,
      //       bteSerialNumber: "BTE0000000099133",
      //       implantSerialNumber: "IMP0000000099133",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "11-Oct-2021",
      //     updatedAt: "11-Oct-2021",
      //     levelcompletionpercentage: 16,
      //   },
      //   {
      //     id: 252,
      //     patientFirstName: "Rajesh",
      //     patientLastName: "Kamalanathan",
      //     patientFullName: "Rajesh Kamalanathan",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "8879657433",
      //     patientEmail: "kamalanathanr@hcl.com",
      //     patientDOB: "04-Dec-2011",
      //     patientImplantDate: "09-Sep-2021",
      //     notes: "physically Fit",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "10 Y",
      //     patientImplantAge: "4 M",
      //     patientImplantGap: "9 Y",
      //     careId: 0,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "21-Sep-2021",
      //       updatedAt: "21-Sep-2021",
      //       id: 62,
      //       bteSerialNumber: "BTE0000000099132",
      //       implantSerialNumber: "IMP0000000099132",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "22-Sep-2021",
      //     updatedAt: "27-Oct-2021",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 251,
      //     patientFirstName: "Amal",
      //     patientLastName: "Samanta",
      //     patientFullName: "Amal Samanta",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "8908294340",
      //     patientEmail: "amal@hcl.com",
      //     patientDOB: "24-Sep-2020",
      //     patientImplantDate: "15-Sep-2021",
      //     notes: "No specific Info",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "1 Y",
      //     patientImplantAge: "3 M",
      //     patientImplantGap: "1 Y",
      //     careId: 360,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "21-Sep-2021",
      //       updatedAt: "21-Sep-2021",
      //       id: 61,
      //       bteSerialNumber: "BTE0000000099131",
      //       implantSerialNumber: "IMP0000000099131",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "21-Sep-2021",
      //     updatedAt: "22-Sep-2021",
      //     levelcompletionpercentage: 71,
      //   },
      //   {
      //     id: 250,
      //     patientFirstName: "Michael",
      //     patientLastName: "John",
      //     patientFullName: "Michael John",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "9845673222",
      //     patientEmail: "michael.john@gmail.com",
      //     patientDOB: "01-Aug-2020",
      //     patientImplantDate: "20-Sep-2021",
      //     notes: "Additional Informations",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "1 Y",
      //     patientImplantAge: "3 M",
      //     patientImplantGap: "1 Y",
      //     careId: 359,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "21-Sep-2021",
      //       updatedAt: "21-Sep-2021",
      //       id: 65,
      //       bteSerialNumber: "BTE0000000099135",
      //       implantSerialNumber: "IMP0000000099135",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "21-Sep-2021",
      //     updatedAt: "21-Sep-2021",
      //     levelcompletionpercentage: 20,
      //   },
      //   {
      //     id: 249,
      //     patientFirstName: "vamsi",
      //     patientLastName: "krishna",
      //     patientFullName: "vamsi krishna",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "8787665456",
      //     patientEmail: "vamsi@hcl.com",
      //     patientDOB: "04-Feb-2021",
      //     patientImplantDate: "02-Sep-2021",
      //     notes: "He is in good condition",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "11 M",
      //     patientImplantAge: "4 M",
      //     patientImplantGap: "7 M",
      //     careId: 358,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "05-Jul-2021",
      //       updatedAt: "05-Jul-2021",
      //       id: 33,
      //       bteSerialNumber: "BTE0000000001598",
      //       implantSerialNumber: "IMP0000000001598",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "14-Sep-2021",
      //     updatedAt: "14-Sep-2021",
      //     levelcompletionpercentage: 100,
      //   },
      //   {
      //     id: 248,
      //     patientFirstName: "ravi",
      //     patientLastName: "kumar",
      //     patientFullName: "ravi kumar",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "9787449245",
      //     patientEmail: "ravikumar@hcl.com",
      //     patientDOB: "04-Feb-2021",
      //     patientImplantDate: "01-Sep-2021",
      //     notes: "patient seems is  in good condition",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "11 M",
      //     patientImplantAge: "4 M",
      //     patientImplantGap: "7 M",
      //     careId: 357,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "20-Jul-2021",
      //       updatedAt: "20-Jul-2021",
      //       id: 43,
      //       bteSerialNumber: "BTE1234567890290",
      //       implantSerialNumber: "IMP1234567890290",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "13-Sep-2021",
      //     updatedAt: "14-Sep-2021",
      //     levelcompletionpercentage: 90,
      //   },
      //   {
      //     id: 247,
      //     patientFirstName: "anjum",
      //     patientLastName: "chopra",
      //     patientFullName: "anjum chopra",
      //     patientGender: "Female",
      //     isVaccinated: null,
      //     patientPhone: "9787449245",
      //     patientEmail: "arjunkumar@hcl.com",
      //     patientDOB: "03-Feb-2021",
      //     patientImplantDate: "01-Sep-2021",
      //     notes: "He is in good condition",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "11 M",
      //     patientImplantAge: "4 M",
      //     patientImplantGap: "7 M",
      //     careId: 0,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "06-Aug-2021",
      //       updatedAt: "06-Aug-2021",
      //       id: 51,
      //       bteSerialNumber: "BTE1234567890270",
      //       implantSerialNumber: "IMP1234567890270",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "13-Sep-2021",
      //     updatedAt: "21-Sep-2021",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 246,
      //     patientFirstName: "Rethina",
      //     patientLastName: "vel",
      //     patientFullName: "Rethina vel",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "9787449245",
      //     patientEmail: "rethina@hcl.com",
      //     patientDOB: "01-Jan-2021",
      //     patientImplantDate: "01-Sep-2021",
      //     notes: "patient seems to be in good condition!",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "1 Y",
      //     patientImplantAge: "4 M",
      //     patientImplantGap: "8 M",
      //     careId: 356,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "06-Aug-2021",
      //       updatedAt: "06-Aug-2021",
      //       id: 50,
      //       bteSerialNumber: "BTE1234567890260",
      //       implantSerialNumber: "IMP1234567890260",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "09-Sep-2021",
      //     updatedAt: "09-Sep-2021",
      //     levelcompletionpercentage: 35,
      //   },
      //   {
      //     id: 245,
      //     patientFirstName: "vikranth",
      //     patientLastName: "kumar",
      //     patientFullName: "vikranth kumar",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "9787449245",
      //     patientEmail: "vikranthkumar@hcl.com",
      //     patientDOB: "01-Jan-2021",
      //     patientImplantDate: "01-Sep-2021",
      //     notes: "patient seems to be in good condition!",
      //     comments: "All does of vaccination done!",
      //     therapistId: 1,
      //     patientAge: "1 Y",
      //     patientImplantAge: "4 M",
      //     patientImplantGap: "8 M",
      //     careId: 355,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "01-Jul-2021",
      //       updatedAt: "01-Jul-2021",
      //       id: 14,
      //       bteSerialNumber: "BTE1234567890130",
      //       implantSerialNumber: "IMP1234567890130",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "08-Sep-2021",
      //     updatedAt: "21-Sep-2021",
      //     levelcompletionpercentage: 15,
      //   },
      //   {
      //     id: 244,
      //     patientFirstName: "sandhiya",
      //     patientLastName: "Rani",
      //     patientFullName: "sandhiya Rani",
      //     patientGender: "Female",
      //     isVaccinated: null,
      //     patientPhone: "9787449245",
      //     patientEmail: "sandhiyarani@hcl.com",
      //     patientDOB: "01-Jan-2021",
      //     patientImplantDate: "01-Sep-2021",
      //     notes: "patient is in good condition!",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "1 Y",
      //     patientImplantAge: "4 M",
      //     patientImplantGap: "8 M",
      //     careId: 354,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "16-Jul-2021",
      //       updatedAt: "16-Jul-2021",
      //       id: 40,
      //       bteSerialNumber: "BTE1000000013898",
      //       implantSerialNumber: "IMP1000000013898",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "08-Sep-2021",
      //     updatedAt: "08-Sep-2021",
      //     levelcompletionpercentage: 5,
      //   },
      //   {
      //     id: 243,
      //     patientFirstName: "kavitha",
      //     patientLastName: "sri",
      //     patientFullName: "kavitha sri",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "9787449245",
      //     patientEmail: "kavithasrii@hcl.com",
      //     patientDOB: "01-Jan-2021",
      //     patientImplantDate: "01-Sep-2021",
      //     notes: "patient is in good condition!",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "1 Y",
      //     patientImplantAge: "4 M",
      //     patientImplantGap: "8 M",
      //     careId: 0,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "16-Jul-2021",
      //       updatedAt: "16-Jul-2021",
      //       id: 41,
      //       bteSerialNumber: "BTE1000000013798",
      //       implantSerialNumber: "BTE1000000013798",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "07-Sep-2021",
      //     updatedAt: "14-Sep-2021",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 241,
      //     patientFirstName: "sanjay",
      //     patientLastName: "kumar",
      //     patientFullName: "sanjay kumar",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "9787449245",
      //     patientEmail: "sanjay12@hcl.com",
      //     patientDOB: "01-Jan-2020",
      //     patientImplantDate: "10-Sep-2020",
      //     notes: "patient is in good condition!",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "2 Y",
      //     patientImplantAge: "1 Y",
      //     patientImplantGap: "9 M",
      //     careId: 0,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "03-Sep-2021",
      //       updatedAt: "03-Sep-2021",
      //       id: 60,
      //       bteSerialNumber: "BTE0200000100098",
      //       implantSerialNumber: "IMP0200000100098",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "07-Sep-2021",
      //     updatedAt: "07-Sep-2021",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 242,
      //     patientFirstName: "aarthi",
      //     patientLastName: "subash",
      //     patientFullName: "aarthi subash",
      //     patientGender: "Female",
      //     isVaccinated: null,
      //     patientPhone: "9787445678",
      //     patientEmail: "aarthisubash@hcl.com",
      //     patientDOB: "01-Jan-2021",
      //     patientImplantDate: "01-Sep-2021",
      //     notes: "patient is in good condition!",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "1 Y",
      //     patientImplantAge: "4 M",
      //     patientImplantGap: "8 M",
      //     careId: 353,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "12-Aug-2021",
      //       updatedAt: "12-Aug-2021",
      //       id: 59,
      //       bteSerialNumber: "BTE0000100023498",
      //       implantSerialNumber: "IMP0000100023498",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "07-Sep-2021",
      //     updatedAt: "07-Sep-2021",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 240,
      //     patientFirstName: "amal",
      //     patientLastName: "sharma",
      //     patientFullName: "amal sharma",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "9000000000",
      //     patientEmail: "amalsharmasss@hcl.com",
      //     patientDOB: "09-Sep-2011",
      //     patientImplantDate: "04-Sep-2014",
      //     notes: "patient is in good condition!",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "10 Y",
      //     patientImplantAge: "7 Y",
      //     patientImplantGap: "3 Y",
      //     careId: 352,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "16-Jul-2021",
      //       updatedAt: "16-Jul-2021",
      //       id: 39,
      //       bteSerialNumber: "BTE0000000000298",
      //       implantSerialNumber: "IMP0000000000298",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "03-Sep-2021",
      //     updatedAt: "06-Sep-2021",
      //     levelcompletionpercentage: 100,
      //   },
      //   {
      //     id: 239,
      //     patientFirstName: "suriya",
      //     patientLastName: "kumar",
      //     patientFullName: "suriya kumar",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "9787449245",
      //     patientEmail: "suriya123@hcl.com",
      //     patientDOB: "01-Jan-2021",
      //     patientImplantDate: "05-Aug-2021",
      //     notes: "patient is in good condition!",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "1 Y",
      //     patientImplantAge: "5 M",
      //     patientImplantGap: "7 M",
      //     careId: 0,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "12-Aug-2021",
      //       updatedAt: "12-Aug-2021",
      //       id: 57,
      //       bteSerialNumber: "BTE0000000090098",
      //       implantSerialNumber: "IMP0000000090098",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "03-Sep-2021",
      //     updatedAt: "03-Sep-2021",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 237,
      //     patientFirstName: "vijay",
      //     patientLastName: "kumar",
      //     patientFullName: "vijay kumar",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "7676767890",
      //     patientEmail: "san@mail.com",
      //     patientDOB: "01-Jan-2021",
      //     patientImplantDate: "04-Aug-2021",
      //     notes: "vijay patient is in good condition!",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "1 Y",
      //     patientImplantAge: "5 M",
      //     patientImplantGap: "7 M",
      //     careId: 0,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "09-Aug-2021",
      //       updatedAt: "09-Aug-2021",
      //       id: 53,
      //       bteSerialNumber: "BTE0000000013798",
      //       implantSerialNumber: "IMP0000000013798",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "11-Aug-2021",
      //     updatedAt: "14-Sep-2021",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 236,
      //     patientFirstName: "Rohan",
      //     patientLastName: "Patil",
      //     patientFullName: "Rohan Patil",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "9898443345",
      //     patientEmail: "rohanpatil@gmail.com",
      //     patientDOB: "01-Jan-2012",
      //     patientImplantDate: "01-Aug-2021",
      //     notes: "patient is in good condition!",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "10 Y",
      //     patientImplantAge: "5 M",
      //     patientImplantGap: "9 Y",
      //     careId: 351,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "10-Aug-2021",
      //       updatedAt: "10-Aug-2021",
      //       id: 56,
      //       bteSerialNumber: "BTE0000000013398",
      //       implantSerialNumber: "IMP0000000013398",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "10-Aug-2021",
      //     updatedAt: "10-Aug-2021",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 233,
      //     patientFirstName: "vinay",
      //     patientLastName: "kumar",
      //     patientFullName: "vinay kumar",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "9787667654",
      //     patientEmail: "vinay-kumar@hcl.com",
      //     patientDOB: "06-Jun-2019",
      //     patientImplantDate: "08-Jul-2021",
      //     notes: "patient is in good condition!",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "2 Y",
      //     patientImplantAge: "6 M",
      //     patientImplantGap: "2 Y",
      //     careId: 350,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "10-Aug-2021",
      //       updatedAt: "10-Aug-2021",
      //       id: 55,
      //       bteSerialNumber: "BTE0000000013298",
      //       implantSerialNumber: "IMP0000000013298",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "10-Aug-2021",
      //     updatedAt: "10-Aug-2021",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 228,
      //     patientFirstName: "rajesh",
      //     patientLastName: "Kumar",
      //     patientFullName: "rajesh Kumar",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "8987678909",
      //     patientEmail: "ashwin11@hcl.com",
      //     patientDOB: "08-Aug-2018",
      //     patientImplantDate: "04-Aug-2021",
      //     notes: "patient is in good condition!",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "3 Y",
      //     patientImplantAge: "5 M",
      //     patientImplantGap: "3 Y",
      //     careId: 0,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "09-Aug-2021",
      //       updatedAt: "09-Aug-2021",
      //       id: 54,
      //       bteSerialNumber: "BTE0000000013098",
      //       implantSerialNumber: "IMP0000000013098",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "09-Aug-2021",
      //     updatedAt: "09-Aug-2021",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 224,
      //     patientFirstName: "Karthick",
      //     patientLastName: "Kumar",
      //     patientFullName: "Karthick Kumar",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "8798765678",
      //     patientEmail: "karthick@hcl.com",
      //     patientDOB: "04-Aug-2020",
      //     patientImplantDate: "05-Aug-2021",
      //     notes: "vaccition done and he is in good condition",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "1 Y",
      //     patientImplantAge: "5 M",
      //     patientImplantGap: "1 Y",
      //     careId: 0,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "04-Aug-2021",
      //       updatedAt: "04-Aug-2021",
      //       id: 49,
      //       bteSerialNumber: "BTE1234567803098",
      //       implantSerialNumber: "IMP1234567803098",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "09-Aug-2021",
      //     updatedAt: "10-Aug-2021",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 220,
      //     patientFirstName: "Velu",
      //     patientLastName: "Chinnadurai",
      //     patientFullName: "Velu Chinnadurai",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "7010171812",
      //     patientEmail: "VeluV@hcl.com",
      //     patientDOB: "12-Aug-2021",
      //     patientImplantDate: "19-Aug-2021",
      //     notes: "vaccition done and he is in good condition",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "4 M",
      //     patientImplantAge: "4 M",
      //     patientImplantGap: "7 D",
      //     careId: 0,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "05-Jul-2021",
      //       updatedAt: "05-Jul-2021",
      //       id: 31,
      //       bteSerialNumber: "BTE0000000000498",
      //       implantSerialNumber: "IMP0000000000498",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "05-Aug-2021",
      //     updatedAt: "05-Aug-2021",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 218,
      //     patientFirstName: "simrannn",
      //     patientLastName: "kanii",
      //     patientFullName: "simrannn kanii",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "9787449123",
      //     patientEmail: "simran@hcl.com",
      //     patientDOB: "01-Jan-2021",
      //     patientImplantDate: "01-Aug-2021",
      //     notes: "vaccition done and he is in good condition",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "1 Y",
      //     patientImplantAge: "5 M",
      //     patientImplantGap: "7 M",
      //     careId: 0,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "26-Jul-2021",
      //       updatedAt: "26-Jul-2021",
      //       id: 46,
      //       bteSerialNumber: "BTE0000000003598",
      //       implantSerialNumber: "IMP0000000003598",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "04-Aug-2021",
      //     updatedAt: "04-Aug-2021",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 219,
      //     patientFirstName: "rahul",
      //     patientLastName: "tiwary",
      //     patientFullName: "rahul tiwary",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "8676789090",
      //     patientEmail: "rahul@hcl.com",
      //     patientDOB: "01-Jun-2020",
      //     patientImplantDate: "01-Apr-2021",
      //     notes: "vaccition done and he is in good condition",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "1 Y",
      //     patientImplantAge: "9 M",
      //     patientImplantGap: "10 M",
      //     careId: 0,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "26-Jul-2021",
      //       updatedAt: "26-Jul-2021",
      //       id: 45,
      //       bteSerialNumber: "BTE0000000013498",
      //       implantSerialNumber: "IMP0000000013498",
      //     },
      //     mobileDeviceNumber: null,
      //     mobileDeviceModel: null,
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "04-Aug-2021",
      //     updatedAt: "04-Aug-2021",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 214,
      //     patientFirstName: "kalyan",
      //     patientLastName: "vendhan",
      //     patientFullName: "kalyan vendhan",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "9000000000",
      //     patientEmail: "savensi@gmail.com",
      //     patientDOB: "03-Mar-2021",
      //     patientImplantDate: "03-Sep-2021",
      //     notes: "vaccition done and he is in good condition",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "10 M",
      //     patientImplantAge: "4 M",
      //     patientImplantGap: "6 M",
      //     careId: 0,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "12-Aug-2021",
      //       updatedAt: "12-Aug-2021",
      //       id: 44,
      //       bteSerialNumber: "BTE0000000003498",
      //       implantSerialNumber: "IMP0000000003498",
      //     },
      //     mobileDeviceNumber: "Mobile1",
      //     mobileDeviceModel: "samsung",
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "04-Aug-2021",
      //     updatedAt: "14-Sep-2021",
      //     levelcompletionpercentage: 0,
      //   },
      //   {
      //     id: 201,
      //     patientFirstName: "sasi",
      //     patientLastName: "dharan",
      //     patientFullName: "sasi dharan",
      //     patientGender: "Male",
      //     isVaccinated: null,
      //     patientPhone: "9000000000",
      //     patientEmail: "sasi@gmail.com",
      //     patientDOB: "04-Aug-2020",
      //     patientImplantDate: "05-Aug-2021",
      //     notes: "vaccition done and he is in good condition",
      //     comments: null,
      //     therapistId: 1,
      //     patientAge: "1 Y",
      //     patientImplantAge: "5 M",
      //     patientImplantGap: "1 Y",
      //     careId: 0,
      //     password: null,
      //     deviceDetails: {
      //       createdAt: "27-Jul-2021",
      //       updatedAt: "27-Jul-2021",
      //       id: 47,
      //       bteSerialNumber: "BTE0000000003698",
      //       implantSerialNumber: "IMP0000000003698",
      //     },
      //     mobileDeviceNumber: "Mobile1",
      //     mobileDeviceModel: "samsung",
      //     patientCategory: "postlingual",
      //     compeltionlevelID: 1,
      //     createdAt: "27-Jul-2021",
      //     updatedAt: "09-Aug-2021",
      //     levelcompletionpercentage: 0,
      //   },
      // ];
      this.searchList = res.data.filter((x) =>
        x.patientFirstName.toLowerCase().includes(searchValue) 
      );
      if (this.searchList.length) {
        // this.searchList = res.patientList;
        this.showSearchListFlag = true;
      } else {
        this.errorMsgFlag = true;
      }
    });
  }

  //Get all patients for search.
  //Get all patients for search.
  getAllPatients(searchValue) {
    let requestBody = {
      keyword: searchValue,
      therapistId: this.therapistId,
      pageNumber: "1",
      recordsPerPage: 10,
    };
    this.api.getPatients(requestBody).subscribe((res) => {
      if (res.data.patientList.length) {
        this.searchList = res.patientList;
        this.showSearchListFlag = true;
      } else {
        this.errorMsgFlag = true;
      }
    });
  }

  //On search of patient
  onSearch(e) {
    this.searchValue = e.target.value;

    if (e.target.value) {
      this.searchTextChanged.next(e.target.value);
    } else {
      this.searchList = [];
    }
  }
  //On Select of individual patient searchh.
  onSelectRow(value) {
    if (this.lessonListFlag) {
      this.localStorage.set("selectedLesson", value);
      this.localStorage.set("selectedLevel", this.selectedLevel);
      this.router.navigateByUrl(
        "therapist/preLingual/patientSummary/lessonGoalPoints"
      );
    } else {
      if (this.flowType === "preLingual") {
        this.localStorage.set("currentSelectedPatient", value);
        this.router.navigateByUrl("therapist/preLingual/patientSummary/stats");
      } else if (this.flowType === "postLingual") {
        this.localStorage.set("currentSelectedPatient", value);
        // this.router.navigate(["therapist/postLingual/reports/home"]);
        this.router.navigateByUrl("therapist/postLingual/reports/home");
      }
    }
  }
  //On Select of individual patient searchh.
  // onSelectRow(value) {
  //   if (this.lessonListFlag) {
  //     this.localStorage.remove("selectedLesson");
  //     // this.router.navigateByUrl("patientDetails/summary/lessonGoalPoints");
  //     // this.router.navigate(["patientDetails/summary/lessonGoalPoints"], {
  //     //   queryParams: { lessonDetails: value },
  //     // });
  //     this.router.navigate(["patientDetails/summary/lessonGoalPoints"], {
  //       state: { value },
  //     });
  //   } else {
  //     this.localStorage.set("currentSelectedPatient", value);
  //     this.router.navigateByUrl("patientDetails/summary");
  //   }
  // }

  trackByFn(index, item) {
    return item; // unique id corresponding to the item
  }

  @HostListener("document:click", ["$event"])
  DocumentClick(event: Event) {
    // console.log(event.target);
    if (this.elem.nativeElement.contains(event.target)) {
      // console.log("inside");
      if (!this.showInputSearchFlag) {
        this.showInputSearchFlag = !this.showInputSearchFlag;
      }
    } else {
      this.showInputSearchFlag = false;
      this.searchList = [];
      this.searchValue = "";
      this.errorMsgFlag = false;
    }
  }
}
