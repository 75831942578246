

<div class="card-wrapper">
  
    <div  class="inner-card mr-2"  *ngFor="let list of vedioSrc; let i = index">
      <div style="width: 169px;">
      <div class="contentDiv" >
        
        <div class="subTitle">
          <!-- <div [ngClass]=" list.src==null ? 'imgDiv': 'imgDiv '" style="background: url(https://medtech-test.s3.us-east-2.amazonaws.com/en/image/thumbnail-image01.png);"> -->
            <div  class="imgDiv" name="thumb" style="background: url({{list.Thumbnailurl}})">
           
 
          <ng-template *ngIf="list.src; then displayVideo; else displayAudio"></ng-template>
          <ng-template #displayVideo>
            <vg-player style="background-color: transparent !important;"><video  class="vid"   #videooo controlsList="nodownload" oncontextmenu = "return false;"
              [src]="list.src" type="video/mp4">
              </video> </vg-player>
 
            <div class="innerbox" id="disp">
              <div class="card-items circle "><button (click)="enlargeVideo(videooo)" class="icon-mt-enlarge innerbtn anim"></button></div>
              <div class="card-items circle "><button  (click)="toggleVideo(videooo)"  [ngClass]="videooo.paused ?'icon-mt-play innerbtn anim':'icon-mt-pause innerbtn anim'"></button></div>
             
 
          </div>
          </ng-template>
 
          <ng-template #displayAudio>
            <audio class="aud" controls [src]="list.audiosrc" controlsList="nodownload" oncontextmenu = "return false;" ></audio>
            <div class="inneraudiobox" id="disp">
             
          </div>
          </ng-template>
           
        
        </div>
          
        
        </div >
        <div style="display: block;">
          <div class="text">{{list.exerciseName}}</div>
          <div class = "subtext">{{list.levelName}}/ {{list.ModuleName}}/{{list.CategoryName }}</div>
        </div>
     
      </div>
    </div>
   </div>
  </div>