import { Injectable, Output } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  @Output() onViewMore = new Subject();
  @Output() lessonDetailsCount = new Subject();
  @Output() patientPerformanceCount = new Subject();
  @Output() patientProgressCount = new Subject();
  @Output() patientDetailsCount = new Subject();
  @Output() navbarSearchList = new Subject();
  @Output() afterDelete = new Subject();
  @Output() showLoader :boolean = false;
  addPatientFlag: boolean = false;
  dashboardHeading: any = {};

  constructor() {}
}
