

<div class="card-wrapper">
    <div  class="inner-card" *ngFor="let list of vedioSrc; let i = index">
      <div class="contentDiv" >
        
        <!-- <div class="subTitle"> -->
          <!-- <div [ngClass]=" list.src==null ? 'imgDiv': 'imgDiv '" style="background: url(https://medtech-test.s3.us-east-2.amazonaws.com/en/image/thumbnail-image01.png);"> -->
            <div class="imgDiv" name="thumb" style="background: url({{list.Thumbnailurl}})">
            <input class="checkbox" type="checkbox" id="checkbox{{i}}" name="fooo" >
          <button *ngIf="list.isDefault==true|| list.isAssigned==true" class="assigned icon-mt-selected" title="Course already assigned" tooltipClass="my-custom-class" placement="top"></button>
        
          
          <ng-template *ngIf="list.src; then displayVideo; else displayAudio"></ng-template>
          <ng-template #displayVideo>
            <vg-player style="background-color: transparent !important;"> 
              <video  class="vid" width="100%" height="100%" #videooo controlsList="nodownload" oncontextmenu = "return false;"
              [src]="" type="video/mp4">
              </video>
            </vg-player>
           
 
            <div class="innerbox" id="disp">
              <div class="card-items circle "><button (click)="enlargeVideo(videooo)" class="icon-mt-enlarge innerbuttton anim "></button></div>
              <div class="card-items circle "><button  (click)="toggleVideo(videooo, list)"  [ngClass]="videooo.paused ?'icon-mt-play innerbuttton anim':'icon-mt-pause innerbuttton anim'"></button></div>
              <!-- <div class="card-items circle "><button   id=questions{{i}} (click)="onLinkedClicked($event)" [ngClass]="this.selectedall==true? 'icon-mt-selected btn':'icon-mt-select btn'"></button></div> -->
              <div  class="card-items circle "><button name="pwd" id=questions{{i}} (click)="onLinkedClicked(list, $event)" Class="icon-mt-select innerbuttton anim" [disabled]="list.isDefault==true"></button></div>
 
          </div>
          </ng-template>
 
          <ng-template #displayAudio>
            <audio class="aud" controls [src]="list.audiosrc" controlsList="nodownload" oncontextmenu = "return false;"></audio>
            <div class="inneraudiobox" id="disp">
              <div  class="card-items circle "><button name="pwd" id=questions{{i}} (click)="onLinkedClicked(list, $event)" Class="icon-mt-select innerbuttton anim" [disabled]="list.isDefault==true"></button></div>
          </div>
          </ng-template>
           
        
        </div>
          
        
        <!-- </div> -->
        <div class="text">{{list.exerciseName}}</div>
      </div>
    </div>
  </div>