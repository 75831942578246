import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LocalStorageService } from "../_services/local-storage/localstorage.service";
import { PatientService } from "../_services/patient/patient.service";
import constant from "src/constants/MTConstants";
import { RestApiService } from "../_services/rest-api/rest-api.service";
import { StringService } from "../_services/string.service";
import { DashboardService } from "../rehab/pre-lingual/dashboard/dashboard.service";
@Component({
  selector: "app-splitpage",
  templateUrl: "./splitpage.component.html",
  styleUrls: ["./splitpage.component.scss"],
})
export class SplitpageComponent implements OnInit {
  preLingual: string = constant.welcomeScreen.displayTextpreLingual;
  postLingual: string = constant.welcomeScreen.displayTextpostLingual;
  title: string = constant.welcomeScreen.title;
  content: string = constant.welcomeScreen.description;
  therapistId: any;
  therapistFullName: string;
  nameCompare: boolean;
  
    
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private patientService: PatientService,
    private dashboardService: DashboardService,
    private api: RestApiService,
    private localStorage: LocalStorageService
   
  ) {}
  ngOnInit(): void {

    this.therapistId = this.localStorage.get("therapistId");
this.getTherapistDetailById();
  }

  onSelect(event) {
    if (event === constant.preLingual) {
      this.localStorage.set(constant.flowType, constant.preLingual);
      this.router.navigateByUrl(constant.RoutingUrl.preLingualDashboard);
    } else if (event === constant.postLingual) {
      this.dashboardService.showLoader=true;
      this.localStorage.set(constant.flowType,constant.postLingual);
      this.router.navigateByUrl(constant.RoutingUrl.postLingualDashboard);
    }
  }

  getTherapistDetailById() {
    let therapistDetails = {
      id: this.therapistId,
    
    };
    this.api.getTherapistDetailsById(therapistDetails).subscribe((response) => {
    //   response = {
    //     "success": true,
    //     "message": "Therpist fetched successfully",
    //     "data": {
    //         "therapistFirstName": "santhosh",
    //         "therapistLastName": "Raj",
    //         "therapistGender": "Female",
    //         "therapistPhone": "9042808041",
    //         "id": 2,
    //         "therapistEmail": "kiran@gmail.com",
    //         "therapistAddress": "No7, Anna nagar,Chennai",
    //         "therapistQualification": "MBBS",
    //         "therapistSpecialization": "Therapist",
    //         "therapistExperience": "6Years",
    //         "therapistisActive": true,
    //         "therapistRemarks": "NA",
    //         "therapistId": 2,
    //         "adminId": 1,
    //         "careId": 8,
    //         "password": null
    //     }
    // }
      console.log("therapist Details",response)
      this.therapistFullName = StringService.joinString(response.data.therapistFirstName,response.data.therapistLastName)
      // this.nameCompare = StringService.compareString("san","san")
      console.log("therapist Details",this.therapistFullName)
      
      // this.therapistFullName =  response.data.therapistFirstName + " " + response.data.therapistLastName;
      this.localStorage.set(constant.loginScreen.therapistName,this.therapistFullName);
     });
  }

  logout() {
    this.modalService.dismissAll();
    this.patientService.clearData();
    sessionStorage.clear();
    localStorage.removeItem(constant.loginScreen.userToken);
    this.router.navigate(["/"]);
  }
}
