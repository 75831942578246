<table class="table table-responsive-sm">
    <ng-template *ngIf="patients?.length; then displayPatientList; else noPatientList"></ng-template>
    <ng-template #displayPatientList>
        <thead style="margin-bottom: 6px;">
            <tr class="font-medium">
                <td>Name</td>
                <!-- <td>Last Name</td> -->
                <td style="white-space: nowrap;">
                    Age Analysis
                    <div style="font-size: 9px; white-space: nowrap; color: #8291A0;font-weight: 600;position: absolute;">
                        <span style="cursor: pointer;" ngbTooltip="Chronological Age" tooltipClass="my-custom-class"
                            placement="bottom">Age</span> |
                        <span style="cursor: pointer;" ngbTooltip="Chronological Implant Age"
                            tooltipClass="my-custom-class" placement="bottom">Implant age</span> |
                        <span style="cursor: pointer;" ngbTooltip="Chronological Gap" tooltipClass="my-custom-class"
                            placement="bottom">Gap</span>
                    </div>
                </td>
                <td>Gender</td>
                <ng-container *ngIf="flowType === 'preLingual'; then preLingualColumns; else postLingualColumns">
                </ng-container>
                <ng-template #preLingualColumns>
                    <td style="white-space: nowrap">Course Level</td>
                    <td colspan="2" style="white-space: nowrap; padding-left: 0px">
                        Lesson Progress
                    </td>
                    <!-- <td>Comments</td> -->
                </ng-template>

                <ng-template #postLingualColumns>
                    <td>SP Serial Number</td>
                    <td>
                        Implant Serial Number
                    </td>

                    <td style="width: 15%;padding-left: 0px;">

                        <div>Course Completion</div>

                        <div style="font-size: 10px; white-space: nowrap; color: #666666">

                            <span style="cursor: pointer;" ngbTooltip="" tooltipClass="my-custom-class"
                                placement="bottom">Basic</span>

                            |

                            <span style="cursor: pointer;" ngbTooltip="" tooltipClass="my-custom-class"
                                placement="bottom">Intermediate</span> |

                            <span style="cursor: pointer;" ngbTooltip="Chronological Gap" tooltipClass="my-custom-class"
                                placement="bottom">Advanced</span>

                        </div>

                    </td>
                    <!-- <td>
                        Course Level
                    </td> -->
                    <!-- <td style="width: 15%;padding-left: 0px;">
                        Course Completion
                    </td> -->
                </ng-template>
                <!-- <td>Course Level</td>
                <td colspan="2" style="white-space: nowrap; padding-left: 0px">
                    Lesson Progress
                </td> -->
                <!-- <td>Comments</td> -->
                <!-- <td>Course Level</td>
                <td>Level %</td> -->
                <td>Action</td>
            </tr>
        </thead>
        <tbody>
            <!-- <div class="card p-0"> -->
            <!-- <div class="card-body p-0"> -->
            <tr *ngFor="let patient of patients; let i = index; trackBy: trackByFn" class="font-regular">
                <td>{{ patient.patientFirstName+" "+patient.patientLastName | titlecase }}</td>
                <!-- <td>{{patient.patientLastName}}</td> -->
                <td [ngClass]="patient.patientAge == '-' ?'alignCenter':null">
                    <div>
                        {{ patient.patientAge }} | {{ patient.patientImplantAge }} |
                        {{ patient.patientImplantGap }}
                    </div>
                </td>
                <td [ngClass]="patient.patientGender == '-' ?'alignCenter':null">{{ patient.patientGender }}</td>
                <ng-container *ngIf="flowType === 'preLingual'; then preLingualRows; else postLingualRows">
                </ng-container>
                <ng-template #preLingualRows>
                    <td [ngClass]="patient.courseLevel == 'NA' ?'alignCenter':null">
                        <span>{{ patient.courseLevel }}</span>
                    </td>
                    <td style="
                width: 28px;
                padding-right: 0px !important;
                padding-left: 0px !important;   ">
                        <span [ngClass]="isStatusAboveLimit(patient) ? 'ok' : 'not-ok'">{{
                            patient.completedCourses
                            }}</span><span style="color: #d6d6d6; font-size: 12px">/{{ patient.totalCourses }}</span>
                    </td>
                    <td style="width: 132px; padding-left: 4px !important">
                        <ngb-progressbar *ngIf="patients" id="pdProgBar" 
                            [type]="isStatusAboveLimit(patient) ? 'primary' : 'danger'" height="8px" 
                            [value]="getCompletedProgress(patient)"></ngb-progressbar>
                    </td>
                    <!-- <td [ngClass]="patient.notes == '-' ?'alignCenter':null">
                        <div class="comments" [ngbTooltip]="patient.notes" tooltipClass="my-custom-class"
                            placement="top" container="body">
                            {{ patient.notes }}
                        </div>
                    </td> -->
                </ng-template>
                <ng-template #postLingualRows>
                    <td>{{patient.patientspSerialnumber}} </td>
                    <td>{{patient.patientimplantSerialnumber}}</td>
                    <td>{{patient.basicLevelCompletion}}% |
                    {{patient.intermediateLevelCompletion}}% |
                    {{patient.advancedLevelCompletion}}%</td>
                </ng-template>
                <td class="action-cell">
                    <div class="d-inline-flex">
                        <div class="icon-mt-view-eye d-flex m-0 icon-action"
                            [ngbTooltip]="flowType === 'preLingual' ? 'Patient Detail Summary' : 'View Patient reports'"
                            tooltipClass="my-custom-class" placement="bottom" container="body"
                            style="width: 32px; background-size: cover" (click)="onView(patient)"></div>
                        <div class="icon-mt-edit d-flex edit-icon icon-action" ngbTooltip="Edit Patient Details"
                            tooltipClass="my-custom-class" placement="bottom" container="body" (click)="onEdit(patient)"></div>
                        <div class="icon-mt-delete d-flex  icon-action"ngbTooltip="Remove Patient"
                            tooltipClass="my-custom-class" placement="bottom" container="body" (click)="onDelete(patient)"></div>
                        <div class="icon-mt-comment d-flex  icon-action"
                            [ngbTooltip]="patient.notes" tooltipClass="my-custom-class" placement="left" container="body"
                            style="margin-right: 0px;">
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </ng-template>
    <ng-template #noPatientList>
        <div class="nodata-class">
            <span>No data available</span>
        </div>
    </ng-template>
</table>