<div style="position: relative;">
<div class="userIcon" (click)="open(content)">
    <img src="/assets/imgs/07_ic_user.svg" class="user-thumb" />
</div>
<ng-template #content let-modal id="commentModal">
  <div class="modalWrapper bottom">
    <div class="modal-header">  
      <div class="userIconLarge">
          <img src="/assets/imgs/33_ic_add_user.svg" class="user-profile" />
      </div>
      <div>
        <!-- <h6>{{therapistname}}</h6> -->
        <!-- <div class="therapist-details">{{careId}}</div> -->
        <!-- <div class="therapist-details">{{therapistPhone}}</div> -->
      </div>
    </div>
    <div class="modal-body">
      <ul (click)="selectOption($event)" style="list-style-type: none;margin-bottom: 0rem;padding-inline-start:0px;">
        <li [ngClass]="list == selectedOption ? 'bg-highlight':null" *ngFor="let list of optionList">{{ list }}</li>       
      </ul>
    </div>
    <!-- <div class="modal-footer">
      <app-button id="dialogSubmit" [buttonText]="'Send to Patient'" (click)="modal.close('Save click')"></app-button>
    </div> -->
  </div>
</ng-template>
</div>
