import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { LocalStorageService } from "src/app/_services/local-storage/localstorage.service";
import { PatientService } from "src/app/_services/patient/patient.service";
import { UiUtilService } from "src/app/_services/ui-utils/ui-util.service";
import { UserAuthenticationService } from "src/app/_services/user-authentication.service";
import constant from "src/constants/MTConstants";

@Component({
  selector: "app-modal-changepassword",
  templateUrl: "./modal-changepassword.component.html",
  styleUrls: ["./modal-changepassword.component.scss"],
})
export class ModalChangepasswordComponent implements OnInit {
  @Input() message: string;
  changePasswordForm: FormGroup;
  credentials: any;
  passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])[A-Za-z](?=.*\d)(?=.*(\W|_)).{7,15}$/
  oldPasswordError: boolean = false;
  newpasswordErrormsg: string;
  oldpasswordErrorMsg: string;
  confirmpasswordmsg: string;
  confirmflag: boolean = false;
  flowType: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private uiUtils: UiUtilService,
    private localStorage: LocalStorageService,
    private router: Router,
    private patientService: PatientService,
    private authenticationService: UserAuthenticationService
  ) { }

  ngOnInit(): void {
    this.credentials = JSON.parse(this.localStorage.get(constant.loginScreen.credentials));
    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ["", Validators.required],
      newPassword: ["", [Validators.required, Validators.pattern(this.passwordPattern)]],
      confirmPassword: ["", [Validators.required, Validators.pattern(this.passwordPattern)]],
    });
    this.flowType = this.localStorage.get(constant.flowType);
  }

  //calling API for change password
  onSubmit() {
    let requestBody = {
      userName: this.credentials.userName,
      oldPassword: this.changePasswordForm.value.oldPassword,
      newPassword: this.changePasswordForm.value.newPassword,
      domainName: constant.org,
    };
    this.authenticationService.OnchangePassword(requestBody).subscribe((data) => {
      if (data.status=="success") {
        this.uiUtils.showChangePasswordSuccessMsg({
          message: data.message,
        });
        this.activeModal.close();
        this.patientService.clearData();
        sessionStorage.clear();
        localStorage.removeItem(constant.loginScreen.userToken);
        this.router.navigate(["/"]);
      } else {
        this.activeModal.close();
        this.uiUtils.showChangePasswordSuccessMsg({
          message: data.message,
        });
      }
    });
  }

  //closing the change password pop up
  onClose() {
    this.activeModal.close();
  }

  //Old password validation
  validateoldpassword() {
    if (this.credentials.password != this.changePasswordForm['controls'].oldPassword.value) {
      this.oldPasswordError = true;
      this.oldpasswordErrorMsg = constant.message.crctPwdEmsg;
      this.confirmflag = false;
    }
    else {
      this.oldPasswordError = false;
      this.oldpasswordErrorMsg = "";
      this.confirmflag = true;
    }
  }

  //New password Validation
  ValidateNewPassword() {
    if (this.changePasswordForm['controls'].confirmPassword.value) {
      if (this.changePasswordForm['controls'].newPassword.value != this.changePasswordForm['controls'].confirmPassword.value) {
        if (this.changePasswordForm['controls'].newPassword.status == constant.inValid) {
          this.newpasswordErrormsg = constant.message.newPwdEmsg;
          this.confirmpasswordmsg = constant.message.cnfrmPwdEmsg;
          this.confirmflag = false;
        }
        else {
          this.newpasswordErrormsg = "";
          this.confirmpasswordmsg = constant.message.cnfrmPwdEmsg;
          this.confirmflag = false;
        }
      }
      else {
        this.confirmpasswordmsg = "";
        this.confirmflag = true;
        this.newpasswordErrormsg = ""
      }
    }
    else {
      this.confirmpasswordmsg = "";
      if (this.changePasswordForm['controls'].newPassword.status == constant.inValid) {
        this.newpasswordErrormsg = constant.message.newPwdEmsg;
      }
      else {
        this.newpasswordErrormsg = "";
      }
    }
  }

  //Confirm password validation
  ValidateConfirmPassword() {
    if (this.changePasswordForm['controls'].newPassword.value != this.changePasswordForm['controls'].confirmPassword.value) {
      this.confirmpasswordmsg = constant.message.cnfrmPwdEmsg;
      this.confirmflag = false
    }
    else {
      this.confirmpasswordmsg = "";
      this.confirmflag = true;
    }
  }
}
