import { Component, OnInit, Input, Output } from "@angular/core";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { RestApiService } from "src/app/_services/rest-api/rest-api.service";
import { PatientService } from "src/app/_services/patient/patient.service";
import { TitleCasePipe } from "@angular/common";
import { LocalStorageService } from "src/app/_services/local-storage/localstorage.service";

export enum ActionItem {
  DELETE,
  EDIT,
  VIEW,
}

@Component({
  selector: "app-patient-list-table",
  templateUrl: "./patient-list-table.component.html",
  styleUrls: ["./patient-list-table.component.scss"],
})
export class PatientListTableComponent implements OnInit {
  @Input() patients: any[];

  @Output() actionItemClick: Subject<any> = new Subject();
  patientDetails: any;
  flowType: any;
  courseLevelTitle: string;
  LessonProgress: string;

  constructor(
    private router: Router,
    private api: RestApiService,
    private patientService: PatientService,
    private titleCase: TitleCasePipe,
    private localStorage: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.flowType = this.localStorage.get("flowType");
    if (this.flowType === "preLingual") {
      this.courseLevelTitle = "Course Level";
      this.LessonProgress = "Lesson Progress";
      // this.patientDetails.patient.courseLevel;
    } else if (this.flowType === "postLingual") {
      this.courseLevelTitle = "BTE Number";
      this.LessonProgress = "Implant Number";
    }
  }

  onActionItemClick(data: any, action: ActionItem): void {
    this.actionItemClick.next({ data, action });
  }

  onDelete(item: any): void {
    if(this.flowType === 'preLingual'){
      this.onActionItemClick(item, ActionItem.DELETE);      
    } else {
      this.onActionItemClick(item, ActionItem.DELETE);      
    }
  }

  onEdit(item: any): void {
    this.onActionItemClick(item, ActionItem.EDIT);
  }

  onView(item: any): void {
    this.onActionItemClick(item, ActionItem.VIEW);
  }

  isStatusAboveLimit(item: any): boolean {
    return item.completedCourses >= 2;
  }
  getCompletedProgress(item: any): number {
    return (item.completedCourses / item.totalCourses) * 100;
  }

  trackByFn(index, item) {
    return item; // unique id corresponding to the item
  }
}
