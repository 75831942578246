<div class="main nav-item dropdown d-inline-block" ngbDropdown placement="bottom-left">

    <button *ngIf="!showInputSearchFlag" #searchbutton id="searchbutton"
        class="btn btn-primary btn-main btn-rounded pad btn-search">
        <div  [ngClass]="lessonListFlag ?'icon-mt-search':'icon-mt-search-white'"></div>
    </button>
    <div *ngIf="showInputSearchFlag" class="input-group search-group" #searchbar>
        <input type="text" ngbDropdownToggle class="form-control" placeholder="Search" (keyup)="onSearch($event)">
        <div class="input-group-append">
            <button class="btn" type="button">
                <div class="icon-mt-search"></div>
            </button>
        </div>
    </div>
    <div *ngIf="showSearchListFlag && this.searchList.length !==0" ngbDropdownMenu
        aria-labelledby="notificationDropdown" class="dropdown-menu dropdown-menu-left dropdownDiv">
        <table class="table table-responsive-sm table-lessons table-no-border m-0">
            <tbody *ngIf="!lessonListFlag">
                <tr *ngFor="let patient of searchList;let i=index;trackBy: trackByFn" ngbDropdownItem
                    (click)="onSelectRow(patient)">
                    <td class="w-25">{{patient.patientFirstName}}</td>
                    <td>
                        <div>{{patient.patientAge}} | {{patient.patientImplantAge || patient.implantAge}} |
                            {{patient.patientImplantGap}}
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="lessonListFlag">
                <tr *ngFor="let lesson of searchList;let i=index;trackBy: trackByFn" ngbDropdownItem
                    (click)="onSelectRow(lesson)">
                    <td class="w-25">{{lesson.courseName}}</td>
                </tr>
            </tbody>
        </table>

    </div>
    <div *ngIf="errorMsgFlag" ngbDropdownMenu aria-labelledby="notificationDropdown"
        class="dropdown-menu dropdown-menu-left dropdownDiv nodata-class">No records found
    </div>
</div>